import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import RenderingPage from "../../components/services/3drendering/index"
const rendering = () => {
  return (
    <Layout>
      <SEO
        title="3D Rendering Services outsource in India - Innoway Systems"
        desc="Innoway Systems 3D model architecture expertise can provide the 3D rendering and perspective animation with excellent visualizations."
        pathname="/services/3d-rendering/"
        keywords="3D Rendering Services in coimbatore,3D model architecture in coimbatore "
      />
      <RenderingPage />
    </Layout>
  )
}

export default rendering
